import React from 'react';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
// import XIcon from '@mui/icons-material/X';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import RssFeedIcon from '@mui/icons-material/RssFeed';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
// import FaxIcon from '@mui/icons-material/Fax';
import ShareIcon from '@mui/icons-material/Share';


const Social = () => {

    const iconStyle = {
        color: '#3F68A1',

        margin: '5px',
        width: '40px',
        height: '40px',
    };



    const phoneNumber = '9498912982'; // phone number
    const whatsappNumber = '+123456789'; //  WhatsApp number
    const emailAddress = 'reserve@chartergoinfissioncabo.com'; // email address
    const Facebook = 'https://www.facebook.com/';
    // const X = 'https://twitter.com/';
    // const YouTube = 'https://www.youtube.com/user/'

    const handlePhoneClick = () => {
        window.location.href = 'tel:' + phoneNumber;
    };

    const handleWhatsAppClick = () => {
        window.location.href = 'https://wa.me/' + whatsappNumber;
    };

    const handleEmailClick = (boat) => {
        const subject = encodeURIComponent(`Goin' Fission`);
        window.location.href = `mailto:${emailAddress}?subject=${subject}`;
    };


    const handleFacebookClick = () => {
        window.open(Facebook, '_blank');

    };

    // const handleXClick = () => {
    //     window.open(X, '_blank');

    // };

    // const handleYouTubeClick = () => {
    //     window.open(YouTube, '_blank');

    // };

    const handleShareClick = () => {
        const pageTitle = document.title; // Capture the current page's title
        const shareData = {
            title: pageTitle,
            text: `Check out this page: ${pageTitle}`,
            url: window.location.href
        };

        if (navigator.share) {
            navigator.share(shareData)
                .then(() => {
                    console.log('Shared successfully');
                })
                .catch((error) => {
                    console.error('Error sharing:', error);
                });
        } else {
            console.log('Web Share API not supported.');
            // Fallback sharing method
            // You can open a modal/dialog with share options
        }
    };

    return (
        <div className='print:invisible'>
            <div className='flex gap-0'>
                <div className='flex mx-auto'>
                    <div className=''>

                        <Button onClick={handlePhoneClick} title="Call Goin' Fission">
                            <PhoneIcon style={iconStyle} />
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleWhatsAppClick} title="Call or Text Goin' Fission on WhatsApp">
                            <WhatsAppIcon style={iconStyle} />
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleEmailClick} title="Send Goin' Fission an E-mail">
                            <EmailIcon style={iconStyle} />
                        </Button>
                    </div>



                    {/* <div>
                        <Button onClick={handleFacebookClick} title="Goin' Fission Facebook">
                            <FacebookIcon style={iconStyle} />
                        </Button>
                    </div> */}
                    {/* <div>
                        <Button onClick={handleXClick} title="Goin' Fission X">
                            <XIcon style={iconStyle} />
                        </Button>
                    </div> */}
                    {/* <div>
                        <Button onClick={handleYouTubeClick} title="Goin' Fission YouTube">
                            <YouTubeIcon style={iconStyle} />
                        </Button>
                    </div> */}


                    <div>
                        <Button onClick={handleShareClick} title='Share the Current page'>
                            <ShareIcon style={iconStyle} />
                        </Button></div>

                </div>

            </div>

        </div>
    )
}

export default Social