import React from 'react';
import { Helmet } from 'react-helmet';
import HalfDayAdventure from '../Charters/HalfDayAdventure';
import FullDayCharter from '../Charters/FullDayCharter';
import WhaleWatchingCharter from '../Charters/WhaleWatchingCharter';
import SunsetCharter from '../Charters/SunsetCharter';
import SnorkelingTrips from '../Charters/SnorkelingTrips';
import PrivateEventsCharter from '../Charters/PrivateEventsCharter';




const Offers = () => {


    return (
        <div className='container mx-auto'>

            <Helmet>
                <title data-react-helmet="true">
                    Explore Our Charter Packages: Full Day, Half Day, Whale Watching, Sunset, Snorkeling, and Private Events
                </title>
                <meta data-react-helmet="true" name="description" content="Discover amazing charter adventures with 'Going Fission.' Choose from Full Day, Half Day, Whale Watching, Sunset, Snorkeling, and Private Events packages tailored to your preferences." />
                <meta data-react-helmet="true" property="og:title" content="Exciting Charter Packages with 'Going Fission'" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/Offers/" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/OGOffers.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Choose from a variety of charter packages including Full Day, Half Day, Whale Watching, Sunset, Snorkeling, and Private Events with 'Going Fission.' Perfect for memorable adventures and special occasions." />
            </Helmet>

            <div className='mx-5'>
                <div className='lg:flex gap-5'>
                    <div className='lg:w-1/2'><FullDayCharter /></div>
                    <div className='lg:w-1/2'><HalfDayAdventure /></div>
                </div>

                <div className='lg:flex gap-5'>
                    <div className='lg:w-1/2'><WhaleWatchingCharter /></div>
                    <div className='lg:w-1/2'><SunsetCharter /></div>
                </div>

                <div className='lg:flex gap-5'>
                    <div className='lg:w-1/2'><SnorkelingTrips /></div>
                    <div className='lg:w-1/2'><PrivateEventsCharter /></div>
                </div>
            </div>

        </div>
    );
}

export default Offers;