import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CompanyName from './Component/CompanyName';
import ImagesData from './Component/GoinFission.json';
import { Helmet } from 'react-helmet';
import { Divider } from '@mui/material';

const GoinFission = () => {

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <div className="container mx-auto">
            <Helmet>
                <title data-react-helmet="true">2001 70' Goin' Fission Hatteras Sport Fisherman - Fishing Charters in Cabo</title>
                <meta data-react-helmet="true" name="description" content="Experience luxury and adventure aboard the 2001 70' Goin' Fission Hatteras Sport Fisherman. Perfect for your next charter with ultimate comfort and performance." />
                <meta data-react-helmet="true" property="og:title" content="2001 70' Goin' Fission Hatteras Sport Fisherman - Fishing Charters in Cabo" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/GoinFission" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/ogGoinFission.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Charter the 2001 70' Goin' Fission Hatteras Sport Fisherman for an unforgettable maritime adventure. Enjoy luxury and comfort on the water." />
                <meta data-react-helmet="true" name="keywords" content="Goin' Fission, Hatteras Sport Fisherman, Fishing Charters, maritime adventure, fishing charter, 2001 Hatteras, yacht charter" />
            </Helmet>
            <div className='mx-5 pt-10'>
                <div className='text-2xl pb-5'><b>2001 70' <CompanyName /> Hatteras Sport Fisherman</b></div>
                <div className='pb-5'>Experience luxury and adventure aboard the 2001 70' Goin' Fission Hatteras Sport Fisherman. Built by Hatteras in 2001 and meticulously restored in 2023, this stunning vessel offers the ultimate in comfort and performance for your next charter.</div>
                <div className=''>Charter the Goin' Fission Hatteras Sport Fisherman for an unforgettable maritime adventure. Whether you're exploring new fishing grounds or relaxing in luxurious surroundings, this vessel promises an exceptional experience on the water.</div>

                <div>
                    <div className='my-5'><Divider /></div>
                    <div className='mb-5 pl-5'>
                        Experience top-tier fishing with our state-of-the-art gear, lines, and tackle—everything you need to reel in your trophy catch or a delicious haul. Book your fishing charter with us and fish like a pro!
                    </div>
                    <Accordion

                        expanded={expanded === 'panel3'}
                        onChange={handleChange('panel3')}
                        sx={{
                            background: '#F4F6F6',

                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        }}

                    >

                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                            style={{ color: '#104895', alignItems: 'left' }}
                        >
                            <div className=''>Fishing Equipment</div>

                        </AccordionSummary>
                        <AccordionDetails style={{ color: '#104895' }}>
                            <div>
                                {ImagesData.filter(image => image.Location === "Equipment").map(filteredImage => (
                                    <div key={filteredImage.ID}>
                                        <div>
                                            <div className='mb-5'>
                                                <img src={filteredImage.Image} alt={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} title={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} className="w-full rounded-3xl shadow-md" />

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='mt-5'>

                    <div className='mb-5 pl-5'>
                        Our charter boat features a rugged and durable exterior, built to handle any fishing adventure. Equipped for the open seas, this vessel is ready to take you where the big catches are, ensuring a reliable and exhilarating experience on the water.                    </div>

                    <Accordion

                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                        sx={{
                            background: '#F4F6F6',

                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            style={{ color: '#104895', alignItems: 'left' }}
                        >
                            <div className=''>Exterior</div>
                        </AccordionSummary>
                        <AccordionDetails style={{ color: '#104895' }}>

                            <div>
                                {ImagesData.filter(image => image.Location === "Exterior").map(filteredImage => (
                                    <div key={filteredImage.ID}>
                                        <div>
                                            <div className='mb-5'>
                                                <img src={filteredImage.Image} alt={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} title={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} className="w-full rounded-t-3xl" />

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='mt-5'>

                    <div className='mb-5 pl-5'>
                        Enjoy the thrill of sport fishing in the lap of luxury with our charter. Our vessel offers the best in sport fishing, paired with an immaculate, high-end interior that ensures your comfort and style on every adventure.
                    </div>

                    <Accordion

                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        sx={{
                            background: '#F4F6F6',

                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        }}

                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            style={{ color: '#104895', alignItems: 'left' }}
                        >
                            <div className=''>Interior</div>
                        </AccordionSummary>
                        <AccordionDetails style={{ color: '#104895' }}>
                            <div>
                                {ImagesData.filter(image => image.Location === "Interior").map(filteredImage => (
                                    <div key={filteredImage.ID}>
                                        <div>
                                            <div className='mb-5'>
                                                <img src={filteredImage.Image} alt={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} title={`2001 70' Goin' Fission Hatteras Sport Fisherman for Charter in Cabo`} className="w-full rounded-t-3xl" />

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </AccordionDetails>
                    </Accordion>
                </div>


            </div>
        </div>
    )
}

export default GoinFission