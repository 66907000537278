import React from 'react';
import CaptainData from '../Component/CaptainDavidMarquez.json';
import { GiCaptainHatProfile } from "react-icons/gi";
import { Helmet } from 'react-helmet';


const CaptainDavidMarquez = () => {
    return (
        <div className="container mx-auto">
            <Helmet>
                <title data-react-helmet="true">Captain David Marquez - Expert Sport Fishing in Cabo San Lucas</title>
                <meta data-react-helmet="true" name="description" content="Learn about Captain David Marquez, a seasoned expert in sport fishing in Cabo San Lucas, Mexico. Discover his experience and dedication to providing exceptional fishing adventures." />
                <meta data-react-helmet="true" property="og:title" content="Captain David Marquez - Expert Sport Fishing in Cabo San Lucas" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/Crew/CaptainDavidMarquez" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/ogCaptainDavidMarquez.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Meet Captain David Marquez, an experienced captain specializing in sport fishing in Cabo San Lucas. Explore his bio and his passion for providing top-notch fishing charters." />
                <meta data-react-helmet="true" name="keywords" content="Captain David Marquez, sport fishing, Cabo San Lucas, fishing charters, fishing adventures, expert captain, Cabo fishing guide" />
            </Helmet>
            <div className='mx-5 pt-10'>
                <div className='text-3xl pb-5'><b>Captain David Marquez</b></div>

                <div className='pb-5'>
                    Captain David Marquez, a seasoned angler with 45 years of fishing experience, has been an integral part of the Goin' Fission team for the past six years. His passion for the sport is evident in his preference for catching the majestic Black Marlin, a testament to his skills and dedication. When he's not out on the water, Captain David enjoys watching soccer and spending time with his large family, which includes four sons and three daughters.
                </div>

                <div className='pb-5'>
                    An aficionado of seafood, his favorite dish is a hearty seafood soup. Fishing with Captain David Marquez is more than just a trip—it's an adventure guided by a true expert. Join him on the waters and experience the thrill of the catch and the joy of fishing with one of the best in the business.
                </div>

                <div className='lg:flex lg:flex-wrap'>
                    {CaptainData.sort((a, b) => b.Date - a.Date).map(Captain => (
                        <div key={Captain.ID} className='lg:w-1/2'>

                            <div>
                                <div className='mb-5 mx-5'>
                                    <img src={Captain.Image} alt={`${Captain.ImageDescription} ----`} title={`${Captain.ImageDescription} ----`} className="w-full rounded-t-3xl" />
                                    <div className='mb-5 py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-b-3xl border-t-8 border-[#ffffff]'>
                                        <div className='pl-5 flex items-center'>
                                            <span className='mr-2'><GiCaptainHatProfile color='black' size={35} /></span>
                                            Captain David Marquez
                                        </div>
                                        <div className='pl-5'>{Captain.ImageDescription} <em>- {Captain.Date}</em></div>

                                    </div>
                                </div>


                            </div>


                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default CaptainDavidMarquez