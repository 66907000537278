import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';

const QR = () => {
    const qrRef = useRef(null);

    useEffect(() => {
        const drawText = () => {
            if (qrRef.current) {
                const canvas = qrRef.current.querySelector('canvas');
                if (canvas) {
                    const ctx = canvas.getContext('2d');

                    // Set font properties
                    ctx.font = '20px "Oregano"';
                    ctx.fillStyle = "#3F68A1";
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    // Text position
                    const textX = canvas.width / 2;
                    const textY = canvas.height / 2;

                    // Draw text
                    ctx.fillText("Goin' Fission", textX, textY);
                }
            }
        };

        // Use a timeout to ensure the QR code is rendered before drawing the text
        setTimeout(drawText, 500);
    }, []);

    const downloadQR = () => {
        if (qrRef.current === null) {
            return;
        }
        toPng(qrRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'qr-code-with-text.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <div>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Oregano:wght@400&display=swap');
            </style>
            <div ref={qrRef} style={{ background: 'transparent' }}>
                <QRCode
                    value={`Company name: Goin' Fission\nSlogan: Fishing Charters in Cabo\nURL: https://chartergoinfissioncabo.com/\nPhone number: 949-891-2982\nEmail: mailto:reserve@chartergoinfissioncabo.com`}
                    size={256}
                    bgColor="transparent"
                    fgColor="#3F68A1"
                />
            </div>
            <button onClick={downloadQR}>Download QR Code</button>
        </div>
    );
};

export default QR;