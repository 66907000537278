import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Link } from "@mui/material";
import Logo from '../Images/Logo.png'
import Weather from './Weather';




const Navigation = () => {

    const [navbar, setNavbar] = useState(false);

    useEffect(() => {
        const addTranslateScript = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(script);
        };

        const initializeTranslate = () => {
            if (window.google && window.google.translate) {
                try {
                    new window.google.translate.TranslateElement(
                        { pageLanguage: 'en' },
                        'google_translate_element'
                    );
                } catch (error) {
                    console.error('Google Translate Element initialization error:', error);
                }
            } else {
                console.warn('Google Translate not loaded.');
            }
        };

        if (!window.googleTranslateElementInit) {
            window.googleTranslateElementInit = initializeTranslate;
            addTranslateScript();
        } else {
            initializeTranslate();
        }
        return () => {
            const script = document.querySelector(
                'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
            );
            if (script) script.remove();
        };
    }, []);


    return (
        <div>
            <nav
                className="w-full border-b-8 border-[#ffffff] dark:text-[#99aab5] bg-[#F4F6F6]"

            >
                <div className="justify-between mx-auto lg:max-w-7xl md:items-center md:flex md:px-0">
                    <div>
                        <div className="flex items-center justify-between md:py-0 md:block">
                            <Link href="/" style={{ textDecoration: "none" }} color={"inherit"} title="Goin' Fission Fishing Charters in Cabo San Lucas, Mexico">
                                <div className="font-bold no-underline mt-4 ml-5">
                                    <div className=''>
                                        <div className=''><img src={Logo} alt="Fishing Charters in Cabo San Lucas" title="Goin' Fission Fishing Charters in Cabo San Lucas, Mexico" className='mx-auto' /></div>
                                        <div className="text-[#0D3B66] pt-1 text-center"><div className='text-sm'>Fishing Charters in Cabo</div></div>
                                    </div>
                                    {/* <h1 className="text-5xl mb-0 oregano-regular text-[#F4F6F6]"><CompanyName /></h1> */}

                                </div>

                            </Link>
                            <div className="md:hidden print:hidden">
                                <button
                                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={`flex-1 justify - self - center pb - 1 mt - 1 md:block md: pb - 0 md: mt - 0 ${navbar ? "block" : "hidden"
                                } `}
                        >
                            <ul className="sm:text-black md:flex md:space-x-0 md:space-y-0 ">


                                <Link
                                    href="/"
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    style={{ textDecoration: "none" }}
                                >

                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Home
                                        </div>
                                    </motion.li>
                                </Link>

                                <Link
                                    href="/Tournaments"
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    style={{ textDecoration: "none" }}
                                >

                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Tournaments
                                        </div>
                                    </motion.li>
                                </Link>


                                <Link
                                    href="/Offers"
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    style={{ textDecoration: "none" }}
                                >

                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Charter Pricing
                                        </div>
                                    </motion.li>
                                </Link>



                                <Link
                                    href='/Testimonials'
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    style={{ textDecoration: "none" }}
                                >

                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Testimonials
                                        </div>
                                    </motion.li>
                                </Link>

                                <Link
                                    href='/GoinFission'
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    style={{ textDecoration: "none" }}
                                >

                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Photos
                                        </div>
                                    </motion.li>
                                </Link>




                                <Link
                                    href="/Contact"
                                    className="dark:text-[#99aab5]"
                                    color={"inherit"}
                                    exact="true"
                                    style={{ textDecoration: "none" }}
                                >
                                    <motion.li style={{ backgroundColor: '#F4F6F6', color: '#3F68A1' }} whileHover={{
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#0D3B66',
                                        color: '#F4F6F6'
                                    }}>
                                        <div className="px-3 py-2 font-bold border-r-2 border-[#348AA7]">
                                            Contact
                                        </div>
                                    </motion.li>
                                </Link>
                                <div>

                                    <div id="google_translate_element" className='pl-5'></div>

                                </div>

                            </ul>
                        </div>
                    </div>
                </div>
            </nav >
            <div className=''><Weather /></div>
        </div >
    )
}

export default Navigation