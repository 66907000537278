import React from 'react';
import { Helmet } from 'react-helmet';
import testimonialsData from './Component/Testimonials.json'; // adjust the path as needed
import { Divider } from '@mui/material';

const Testimonials = () => {
    return (
        <div className='container mx-auto pt-10'>
            <Helmet>
                <title data-react-helmet="true">Customer Testimonials | Charter Trips Aboard Goin Fission</title>
                <meta data-react-helmet="true" name="description" content="Read testimonials from our customers who enjoyed unforgettable charter trips aboard Goin Fission in Cabo San Lucas. Discover why they love fishing with us!" />
                <meta data-react-helmet="true" property="og:title" content="Customer Testimonials | Charter Trips Aboard Goin Fission" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/Testimonials/" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/oghome.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Hear from our customers about their amazing fishing experiences aboard Goin Fission in Cabo San Lucas. Find out what makes our charter trips special!" />
            </Helmet>
            <div className='bg-[#F4F6F6] p-6 rounded-lg shadow-md'>
                {testimonialsData.map((testimonial) => (
                    <div key={testimonial.ID} className='mb-8'>


                        <blockquote className='italic rounded-2xl shadow-md bg-white'>


                            <div>
                                {/* Conditionally render image if provided */}
                                {testimonial.Image && (
                                    <div className='mb-4 rounded-t-2xl'>
                                        <img src={testimonial.Image} alt={testimonial.Name} className='w-full h-auto rounded-t-2xl shadow-md' />
                                    </div>
                                )}

                                {/* Conditionally render YouTube video if provided */}
                                {testimonial.YouTube && (
                                    <div className='mb-4'>
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            className='rounded-t-2xl rounded-sm shadow-md mx-auto w-full aspect-video align-middle'
                                            src={`https://www.youtube.com/embed/${testimonial.YouTube}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                )}
                            </div>

                            <p className='mb-2 ml-5 pt-5'>{testimonial.Paragraph1}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph2}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph3}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph4}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph5}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph6}</p>
                            <p className='mb-2 ml-5'>{testimonial.Paragraph7}</p>
                            <div className='my-5'><Divider /></div>
                            <div className='lg:flex mx-10 pb-5'>
                                <div className='lg:w-1/2 text-left'>{testimonial.Date}</div>
                                <div className='lg:w-1/2 text-right signature2 font-bold text-5xl'>{testimonial.Name}</div>
                            </div>
                        </blockquote>




                    </div>
                ))}
            </div>
        </div>
    );
}

export default Testimonials;
