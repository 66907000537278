import React from 'react';


const CharterIncluded = () => {
    return (
        <div>
            <div className='text-sm lg:pl-5'>

                <div>All Bait</div>
                <div>Fishing Licenses</div>
                <div>All Taxes</div>
                <div>Fishing tackle and equipment</div>
                <div>Dock fees</div>
                <div>Safety equipment</div>
                <div>Bait and equipment prep</div>
                <div>First aid equip</div>
                <div>*Fuel</div>
                <div>Ice, Soda, Domestic beer, Wine	</div>
                <div>Fish fileting</div>
                <div>Breakfast and lunch (full day)</div>
                <div>Breakfast or Lunch (half day)</div>
            </div>
        </div>
    )
}

export default CharterIncluded