import React from 'react';
import { Helmet } from 'react-helmet';
import PelagicTripleCrownofFishing from './Tournaments/PelagicTripleCrownofFishing';
import BisbeesEastCapeTournament from './Tournaments/BisbeesEastCapeTournament';
import LosCabosBillfishTournament from './Tournaments/LosCabosBillfishTournament';
import BisbeesLosCabosOffshore from './Tournaments/BisbeesLosCabosOffshore';
import BisbeesBlackandBlueMarlinTournament from './Tournaments/BisbeesBlackandBlueMarlinTournament';
import LosCabosTunaJackpotTournament from './Tournaments/LosCabosTunaJackpotTournament';


const Tournaments = () => {
    return (
        <div>
            <Helmet>
                <title data-react-helmet="true">Goin' Fission - Cabo Fishing Tournaments: Explore Our Tournament Packages</title>
                <meta data-react-helmet="true" name="description" content="Explore top fishing tournaments in Cabo, including the Pelagic Triple Crown, Bisbee's East Cape, Los Cabos Billfish, Bisbee's Offshore, Black and Blue Marlin, and Tuna Jackpot." />
                <meta data-react-helmet="true" property="og:title" content="Goin' Fission - Cabo Fishing Tournaments: Explore Our Tournament Packages" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/Tournaments/" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/oghome.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Explore top fishing tournaments in Cabo, including the Pelagic Triple Crown, Bisbee's East Cape, Los Cabos Billfish, Bisbee's Offshore, Black and Blue Marlin, and Tuna Jackpot." />
            </Helmet>
            <div className='text-3xl py-5 text-center'><h1>Cabo Tournaments</h1></div>



            <div><LosCabosBillfishTournament /></div>

            <div><BisbeesLosCabosOffshore /></div>

            <div><BisbeesBlackandBlueMarlinTournament /></div>

            <div><LosCabosTunaJackpotTournament /></div>

            <div><PelagicTripleCrownofFishing /></div>

            <div><BisbeesEastCapeTournament /></div>

            {/* <div className=''>
                {TournamentsData.map(tournament => (
                    <div key={tournament.ID} >
                        <div className='lg:flex py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-t-3xl border-b-8 border-[#ffffff]'>
                            <div className='lg:w-1/2 pl-5'><h3><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></h3></div>
                            <div className='lg:w-1/2 lg:pr-10 pl-5 lg:text-right'><CalendarMonthTwoToneIcon /> {tournament.From} - {tournament.To} {tournament.TournamentsYear}</div>
                        </div>
                        <div className="relative">
                            <div className='bg-[#F4F6F6] text-[#3F68A1]'>
                                <Link href={tournament.TournamentLink}>
                                    <img src={tournament.TournamentImage} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo from ${tournament.From} to ${tournament.To} ${tournament.TournamentsYear}`} className="w-full hover:mix-blend-multiply" />
                                </Link>
                            </div>
                            <div className=''>
                                <img src={tournament.TournamentLogo} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} className="absolute lg:w-1/5 w-1/3 bottom-0 right-0 bg-[#F4F6F6]/50 text-[#3F68A1] rounded-tl-3xl p-5" />
                            </div>

                        </div>
                        <div className='lg:flex mb-5 py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-b-3xl border-t-8 border-[#ffffff]'>
                            <div className='lg:w-3/4 pl-5'>
                                <div><h3><b>Price:  <span className='text-[#B83855]'>${tournament.TournamentPrice}</span></b></h3></div>
                                <div><em>The price includes all food and drinks</em></div>

                            </div>
                            <div className='lg:w-1/4 pl-5 lg:text-center'>
                                <h3><b>{tournament.TournamentDuration} Days - <span className='animate-pulse'>{tournament.Availability}</span></b></h3>
                                <div className='pb-5'><Link href={tournament.EntryFeesandSchedule}>Entry Fees & Schedule</Link></div>
                            </div>
                            <div className='lg:w-1/4 lg:text-right lg:mt-0 mt-5 pl-5 lg:pr-10'>
                                <ThemeProvider theme={theme}>
                                    <Button

                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        component="a"
                                        href={tournament.TournamentLink}
                                        title={`Reservation of ${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo on Goin' Fission`}

                                    >
                                        <GiFishing size={35} />
                                        &nbsp;&nbsp;
                                        <b className='Custom-Font'>Learn More</b>
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>

                    </div>

                ))}

            </div> */}
        </div>
    )
}

export default Tournaments