import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from './Home';
import GoinFission from "./GoinFission";
import CaptainDavidMarquez from "./Crew/CaptainDavidMarquez";
import Contact from "./Contact";
import Tournaments from "./Tournaments";
import Testimonials from './Testimonials';
import BGGF from './Images/GoinFission-1.jpg';
import Footer from './Component/Footer';
import PelagicTripleCrownofFishing from "./Tournaments/PelagicTripleCrownofFishing";
import './App.css';
import Navigation from "./Component/Navigation";
import Offers from './Component/Offers';
import QR from './Component/QR';
import FullDayCharterReservation from './Charters/FullDayCharterReservation';
import HalfDayAdventureReservation from './Charters/HalfDayAdventureReservation';
import WhaleWatchingCharterReservation from './Charters/WhaleWatchingCharterReservation';
import SunsetCharterReservation from './Charters/SunsetCharterReservation';
import SnorkelingTripsReservation from './Charters/SnorkelingTripsReservation';
import PrivateEventsCharterReservation from './Charters/PrivateEventsCharterReservation';
import PelagicTripleCrownofFishingReservation from './Tournaments/PelagicTripleCrownofFishingReservation';
import BisbeesEastCapeTournamentReservation from './Tournaments/BisbeesEastCapeTournamentReservation';
import LosCabosBillfishTournamentReservation from './Tournaments/LosCabosBillfishTournamentReservation';
import BisbeesLosCabosOffshoreReservation from './Tournaments/BisbeesLosCabosOffshoreReservation';
import BisbeesBlackandBlueMarlinTournamentReservation from './Tournaments/BisbeesBlackandBlueMarlinTournamentReservation';
import LosCabosTunaJackpotTournamentReservation from './Tournaments/LosCabosTunaJackpotTournamentReservation';

// https://dev.to/codelover405/google-translate-api-in-a-reactjs-2697

const history = createBrowserHistory();

function App() {





  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optionally, you can add smooth scrolling effect
    });
  };

  useEffect(() => {
    // Call the scrollToTop function when the component mounts or updates
    scrollToTop();
  }, []);

  return (
    <div className="min-h-screen bg-cover bg-center bg-fixed text-[#3F68A1] Custom-Font"
      style={{ backgroundImage: `url(${BGGF})` }}>



      <Navigation />

      <div className='bg-[#F4F6F6]/70'>

        <BrowserRouter history={history}>



          <Switch>

            <Route exact="true" path="/" component={Home} />
            <Route exact="true" path="/GoinFission" component={GoinFission} />
            <Route exact="true" path="/Crew/CaptainDavidMarquez" component={CaptainDavidMarquez} />
            <Route exact="true" path="/Offers" component={Offers} />
            <Route exact="true" path="/Tournaments" component={Tournaments} />
            <Route exact="true" path="/Tournaments/PelagicTripleCrownofFishing" component={PelagicTripleCrownofFishing} />
            <Route exact="true" path="/Testimonials" component={Testimonials} />
            <Route exact="true" path="/Contact" component={Contact} />
            <Route exact="true" path="/QR" component={QR} />
            <Route exact="true" path="/Charters/FullDayCharterReservation" component={FullDayCharterReservation} />
            <Route exact="true" path="/Charters/HalfDayAdventureReservation" component={HalfDayAdventureReservation} />
            <Route exact="true" path="/Charters/WhaleWatchingCharterReservation" component={WhaleWatchingCharterReservation} />
            <Route exact="true" path="/Charters/SunsetCharterReservation" component={SunsetCharterReservation} />
            <Route exact="true" path="/Charters/SnorkelingTripsReservation" component={SnorkelingTripsReservation} />
            <Route exact="true" path="/Charters/PrivateEventsCharterReservation" component={PrivateEventsCharterReservation} />
            <Route exact="true" path="/Tournaments/PelagicTripleCrownofFishingReservation" component={PelagicTripleCrownofFishingReservation} />
            <Route exact="true" path="/Tournaments/BisbeesEastCapeTournamentReservation" component={BisbeesEastCapeTournamentReservation} />
            <Route exact="true" path="/Tournaments/LosCabosBillfishTournamentReservation" component={LosCabosBillfishTournamentReservation} />
            <Route exact="true" path="/Tournaments/BisbeesLosCabosOffshoreReservation" component={BisbeesLosCabosOffshoreReservation} />
            <Route exact="true" path="/Tournaments/BisbeesBlackandBlueMarlinTournamentReservation" component={BisbeesBlackandBlueMarlinTournamentReservation} />
            <Route exact="true" path="/Tournaments/LosCabosTunaJackpotTournamentReservation" component={LosCabosTunaJackpotTournamentReservation} />
          </Switch>

        </BrowserRouter >
        <Footer />
      </div>
    </div >
  );
}

export default App;
