import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';

export default function ContactList() {

    const phoneNumber = '949 891 2982'; //
    const emailaddress = 'reserve@chartergoinfissioncabo.com';

    const handlePhoneClick = () => {
        window.location.href = 'tel:' + phoneNumber;
    };

    const handleEmailClick = () => {
        window.location.href = 'emailto:' + emailaddress;
    };

    return (
        <List sx={{ bgcolor: '#F4F6F6' }}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Button onClick={handlePhoneClick} title="Call Goin' Fission">
                        <PhoneIcon />
                    </Button>
                </ListItemAvatar>
                <ListItemText
                    primary="Call"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                            </Typography>

                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <Button onClick={handleEmailClick} title="Call Goin' Fission">
                    <EmailIcon />
                </Button>
                <ListItemText
                    primary="E-mail"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <Link href='mailto:reserve@chartergoinfissioncabo.com' title=''>reserve@chartergoinfissioncabo.com</Link>
                            </Typography>

                        </React.Fragment>
                    }
                />
            </ListItem>

        </List>
    );
}