import React, { useState, useEffect } from 'react';
import TournamentsData from '../Component/Tournaments.json';
import { Link } from '@mui/material';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GiFishing } from "react-icons/gi";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Divider } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { FaPeopleGroup } from "react-icons/fa6";
import { GiCaptainHatProfile } from "react-icons/gi";
import { LuPartyPopper } from "react-icons/lu";
import { GiSawedOffShotgun } from "react-icons/gi";
import { GiBoatFishing } from "react-icons/gi";
import { GiFishingPole } from "react-icons/gi";
import { MdScale } from "react-icons/md";
import { MdOutlineScale } from "react-icons/md";
import { MdStart } from "react-icons/md";



const theme = createTheme({
    palette: {
        primary: {
            main: "#B83855", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

const PelagicTripleCrownofFishing = () => {

    const [tournaments, settournaments] = useState([]);

    useEffect(() => {
        // Filter the offers data to only include those with the TournamentName "Pelagic Triple Crown of Fishing"
        const filteredtournaments = TournamentsData.filter(tournament => tournament.TournamentName === "Pelagic Triple Crown of Fishing");
        console.log("Filtered Tournaments:", filteredtournaments); // Debugging
        settournaments(filteredtournaments);
    }, []);


    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <div className="container mx-auto">
            <div className='mx-5'>
                {tournaments.map(tournament => (
                    <div key={tournament.ID}>
                        <div className='lg:flex py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-t-3xl border-b-8 border-[#ffffff]'>
                            <div className='lg:w-1/2 pl-5'>
                                <h3><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></h3>
                            </div>
                            <div className='lg:w-1/2 lg:pr-10 pl-5 lg:text-right'>
                                <div><CalendarMonthTwoToneIcon /> {tournament.From} - {tournament.To} {tournament.TournamentsYear} <span className='animate-pulse text-[#B83855]'>{tournament.Availability}</span></div>

                            </div>

                        </div>
                        <div className="relative">
                            <div className='bg-[#F4F6F6] text-[#3F68A1]'>
                                <Link href={tournament.TournamentLink}>
                                    <img src={tournament.TournamentImage} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo from ${tournament.From} to ${tournament.To} ${tournament.TournamentsYear}`} className="w-full hover:mix-blend-multiply" />
                                </Link>
                            </div>
                            <div className=''>
                                <img src={tournament.TournamentLogo} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} className="absolute lg:w-1/5 w-1/3 bottom-0 right-0 bg-[#F4F6F6]/50 text-[#3F68A1] rounded-tl-3xl p-5" />
                            </div>
                        </div>
                        <div className=' mb-5 py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-b-3xl border-t-8 border-[#ffffff]'>
                            <div className='lg:flex'>
                                <div className='lg:w-1/2 pl-5'>
                                    <div>
                                        <h3><b>Price: <span className='text-[#B83855]'>${tournament.TournamentPrice}</span></b> - {tournament.TournamentDuration} Days</h3>
                                    </div>
                                    <div><em>The price includes all food and drinks</em></div>
                                </div>

                                <div className='lg:w-1/2 lg:text-right lg:mt-0 mt-5 pl-5 lg:pr-10'>
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            component="a"
                                            href={tournament.TournamentLink}
                                            title={`Reservation of ${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo on Goin' Fission`}
                                        >
                                            <GiFishing size={35} />
                                            &nbsp;&nbsp;
                                            <b className='Custom-Font'>Check Availability</b>
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div className='px-10 mt-5'>
                                <Accordion

                                    expanded={expanded === 'panel1'}
                                    onChange={handleChange('panel1')}
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        '&:before': {
                                            display: 'none',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}

                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ color: '#104895', alignItems: 'left' }}
                                    >
                                        <div className=''>Entry Fees & Schedule</div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ color: '#104895' }}>
                                        <div className='text-center'><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></div>
                                        <div className='my-2'><Divider /></div>
                                        <div className='text-center pb-5'>Tournament Entry Fees and Optional Jackpots</div>
                                        <TableContainer sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow sx={{ backgroundColor: '#F4F6F6' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Base Entry Fee:</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>$3,500.00</b></TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ backgroundColor: '#ffffff' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Wahoo</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>$500.00 - $2,000.00 - Daily Optional</b></TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ backgroundColor: '#F4F6F6' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Dorado</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>$500.00 - $5,000.00 - Daily Optional</b></TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ backgroundColor: '#ffffff' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Tuna</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>$500.00 - $5,000.00 - Daily Optional</b></TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ backgroundColor: '#F4F6F6' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Billfish Release</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>$500.00 - $10,000.00 - Daily Optional</b></TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ backgroundColor: '#ffffff' }}>
                                                        <TableCell sx={{ color: '#3F68A1' }}><b>Species:</b></TableCell>
                                                        <TableCell align="right" sx={{ color: '#3F68A1' }}><b>Billfish Release, Tuna, Dorado, Wahoo</b></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div className='text-center pt-5'><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></div>
                                        <div className='my-2'><Divider /></div>
                                        <div className='text-center pb-5'>Schedule of Events</div>

                                        <div className='lg:flex lg:gap-5'>
                                            <div className='lg:w-1/4 mx-auto'>
                                                <div className='bg-[#ffffff] text-[#3F68A1] mb-5 rounded-3xl border-2 border-[#ffffff] drop-shadow-md'>
                                                    <div className='pt-3 pl-5 pb-2'>
                                                        <div className='text-left text-sm'>Thursday June 6th</div>
                                                        <div className='text-left text-sm'>Baja Cantina</div>
                                                        <div className='text-left text-sm'>Marina Cabo San Lucas</div>
                                                    </div>
                                                    <div className='border-t-4 border-[#F4F6F6]'></div>
                                                    <div className='pt-3 p-5'>
                                                        <div className='flex items-center'>
                                                            <div><FaPeopleGroup size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pl-5 text-left text-sm'> 2:00PM to 6:00PM Mandatory Team Check In/Registration</div>
                                                        </div>
                                                        <div className='my-2'><Divider /></div>

                                                        <div className='flex items-center'>
                                                            <div><GiCaptainHatProfile size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pl-5 text-left text-sm'> 6:30PM Captain's Meeting</div>
                                                        </div>


                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><LuPartyPopper size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>7:00PM - 8:30PM Kick Off Party</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='lg:w-1/4 mx-auto'>
                                                <div className='bg-[#ffffff] text-[#3F68A1] mb-5 rounded-3xl border-2 border-[#ffffff] drop-shadow-md'>
                                                    <div className='pt-3 pl-5 pb-2'>
                                                        <div className='text-left text-sm'>Friday June 7th - Fishing</div>
                                                        <div className='text-left text-sm pb-5'>Day 1</div>
                                                    </div>
                                                    <div className='border-t-4 border-[#F4F6F6]'></div>
                                                    <div className='pt-3 p-5'>
                                                        <div className='flex items-center'>
                                                            <div><MdStart size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pl-5 text-left text-sm'>6:45AM - Starting Line</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiSawedOffShotgun size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>7:00AM - Shotgun Start</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiBoatFishing size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>7:00AM - Lines-In/Start Fishing</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiFishingPole size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>4:00PM - Lines-Out/Stop Fishing</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><MdScale size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>4:00PM - 6:30PM Weigh In/Video Release Review</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><MdOutlineScale size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>6:30PM Scales Close</div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='lg:w-1/4 mx-auto'>
                                                <div className='bg-[#ffffff] text-[#3F68A1] mb-5 rounded-3xl border-2 border-[#ffffff] drop-shadow-md'>
                                                    <div className='pt-3 pl-5 pb-2'>
                                                        <div className='text-left text-sm'>Saturday June 8th - Fishing</div>
                                                        <div className='text-left text-sm pb-5'>Day 2</div>
                                                    </div>
                                                    <div className='border-t-4 border-[#F4F6F6]'></div>
                                                    <div className='pt-3 p-5'>
                                                        <div className='flex items-center'>
                                                            <div><MdStart size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pl-5 text-left text-sm'>6:45AM - Starting Line</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiSawedOffShotgun size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>7:00AM - Shotgun Start</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiBoatFishing size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>7:00AM - Lines-In/Start Fishing</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><GiFishingPole size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>4:00PM - Lines-Out/Stop Fishing</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><MdScale size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>4:00PM - 6:30PM Weigh In/Video Release Review</div>
                                                        </div>

                                                        <div className='my-2'><Divider /></div>
                                                        <div className='flex items-center'>
                                                            <div><MdOutlineScale size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>6:30PM Scales Close</div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='lg:w-1/4 mx-auto'>
                                                <div className='bg-[#ffffff] text-[#3F68A1] rounded-3xl border-2 border-[#ffffff] drop-shadow-md'>
                                                    <div className='pt-3 pl-5 pb-2'>
                                                        <div className='text-left text-sm'>Thursday June 6th</div>
                                                        <div className='text-left text-sm'>Baja Cantina</div>
                                                        <div className='text-left text-sm'>Marina Cabo San Lucas</div>
                                                    </div>
                                                    <div className='border-t-4 border-[#F4F6F6]'></div>
                                                    <div className='pt-3 p-5'>


                                                        <div className='flex items-center'>
                                                            <div><LuPartyPopper size={35} className="mr-2 border-b-2 border-[#3F68A1] mb-2" /></div>
                                                            <div className='pt-2 pl-5 text-left text-sm'>5:00PM - 7:00PM - Awards Ceremony and Party</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PelagicTripleCrownofFishing