import React from 'react'

const Cancellations = () => {
    return (
        <div className='text-sm lg:pl-5'>
            <div className='pb-3'>Cancellations must be received at least 72 hours prior to the charter date (no exceptions)</div>
            <div>Cancellations will incur a fee of 25% of the total charter cost.</div>
        </div>
    )
}

export default Cancellations