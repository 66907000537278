import React, { useState, useEffect } from 'react';
import offersData from '../Component/Offers.json';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CharterIncluded from '../Component/CharterIncluded';
import { Divider } from '@mui/material';
import Cancellations from '../Component/Cancellations';
import Deposit from '../Component/Deposit';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import DirectionsBoatFilledTwoToneIcon from '@mui/icons-material/DirectionsBoatFilledTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GiFishingBoat } from "react-icons/gi";
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

const theme = createTheme({
    palette: {
        primary: {
            main: "#B83855", // custom primary color

        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

const FullDayCharter = () => {
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        // Filter the offers data to only include those with the title "Full Day Charter"
        const filteredOffers = offersData.filter(offer => offer.title === "Full Day Charter");
        setOffers(filteredOffers);
    }, []);

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <div className='container mx-auto pt-10'>
            <div className="">
                {offers.map((offer, index) => (
                    <div key={index}>
                        <div className='lg:flex py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-t-3xl border-b-8 border-[#ffffff]'>
                            <div className='pl-5'>
                                <h3 style={{ display: 'flex', alignItems: 'center' }}>

                                    <b>{offer.title} Adventure</b>
                                </h3>
                            </div>
                        </div>
                        <div className="relative">
                            <div className='bg-[#F4F6F6] text-[#3F68A1]'>
                                <img
                                    src={offer.image}
                                    alt={`${offer.title} Adventure Charter Fishing in Cabo ${offer.Duration}`}
                                    title={`${offer.title} Adventure Charter Fishing in Cabo ${offer.Duration}`}
                                    className="w-full hover:mix-blend-multiply"
                                />
                            </div>
                        </div>
                        <div className='p-5 bg-[#F4F6F6] text-[#3F68A1] rounded-b-3xl border-t-8 border-[#ffffff]'>
                            <div className='lg:flex'>
                                <div className='lg:w-1/2'>
                                    <div className='text-[#B83855] pb-5 text-3xl'>{offer.Price}</div>
                                </div>
                                <div className='lg:w-1/2'>
                                    <div className='text-[#B83855] pb-5 text-3xl lg:text-right'>
                                        <ThemeProvider theme={theme}>
                                            <Button

                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                component="a"
                                                href={offer.Link}
                                                title={`Reservation of ${offer.title} Adventure in Cabo on Goin' Fission`}

                                            >
                                                <GiFishingBoat size={35} />
                                                &nbsp;&nbsp;
                                                <b className='Custom-Font'>Make a Reservation</b>
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>

                            <div><AccessTimeTwoToneIcon className='mr-3' />{offer.Duration}</div>
                            <div className='my-2'><Divider /></div>
                            <div><PeopleTwoToneIcon className='mr-3' />{offer.People}</div>
                            <div className='my-2'><Divider /></div>
                            <div><GroupAddTwoToneIcon className='mr-3' />{offer.Note1}</div>
                            <div className='my-2'><Divider /></div>
                            <div><LocalBarTwoToneIcon className='mr-3' />{offer.Note2}</div>
                            <div className='my-2'><Divider /></div>
                            <div><HotelTwoToneIcon className='mr-3' />{offer.LiveAboard}</div>
                            <div className='my-2'><Divider /></div>
                            <div><LogoutTwoToneIcon className='mr-3' />{offer.CheckOut}</div>
                            <div className='my-2'><Divider /></div>

                            <div>
                                <Accordion

                                    expanded={expanded === 'panel1'}
                                    onChange={handleChange('panel1')}
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        '&:before': {
                                            display: 'none',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}

                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ color: '#104895', alignItems: 'left' }}
                                    >
                                        <div className=''><DirectionsBoatFilledTwoToneIcon /> Included with Charter:</div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ color: '#104895' }}>

                                        <CharterIncluded />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <Divider />
                            <div>
                                <Accordion

                                    expanded={expanded === 'panel2'}
                                    onChange={handleChange('panel2')}
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        '&:before': {
                                            display: 'none',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}

                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={{ color: '#104895', alignItems: 'left' }}
                                    >
                                        <div className=''><CancelTwoToneIcon /> Cancellations:</div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ color: '#104895' }}>

                                        <Cancellations />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <Divider />
                            <div>
                                <Accordion

                                    expanded={expanded === 'panel3'}
                                    onChange={handleChange('panel3')}
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        '&:before': {
                                            display: 'none',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}

                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                        style={{ color: '#104895', alignItems: 'left' }}
                                    >
                                        <div className=''><PaidTwoToneIcon /> Deposit:</div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ color: '#104895' }}>

                                        <Deposit />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
}
export default FullDayCharter