import React from 'react'

function CompanyName() {
    const CompanyName = "Goin' Fission"


    return (
        <>
            <span aria-label="Goin' Fission" title='Best Charter Fishing Experience in Cabo'>{CompanyName}</span>


        </>
    )
}

export default CompanyName