import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Container, Typography, ThemeProvider, createTheme } from '@mui/material';
import { FaUser, FaPhone, FaEnvelope, FaCommentDots } from 'react-icons/fa';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { IoMdSend } from 'react-icons/io';
import { FaPeopleGroup } from "react-icons/fa6";
import emailjs from 'emailjs-com';
import { IoCalendar } from "react-icons/io5";

const theme = createTheme({
    palette: {
        primary: {
            main: "#B83855", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

const HalfDayAdventureReservation = () => {


    const formik = useFormik({
        initialValues: {
            fullname: '',
            phonenumber: '',
            email: '',
            charterDate: '',
            guests: '',
            charter: '',
            message: '',
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Full Name is required'),
            phonenumber: Yup.string().required('Phone Number is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            charterDate: Yup.string().required('Charter Date is required'),
            guests: Yup.string().required('Number of Guests is required'),
            message: Yup.string().required('Message is required'),
        }),
        onSubmit: (values, { resetForm }) => {
            emailjs.sendForm('service_ylpkqul', 'template_wowzeg6', '#contactForm', 'ljj8dcz8Z_jg6pnQu')
                .then((result) => {
                    console.log(result.text);
                    alert('Message sent successfully!');
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to send the message, please try again.');
                });
        },
    });

    return (
        <div>

            <div className='pt-10'>
                <ThemeProvider theme={theme}>
                    <Container >
                        <form id="contactForm" onSubmit={formik.handleSubmit}>
                            <Typography variant="h5" color="primary" gutterBottom>
                                Half Day Charter Reservation
                            </Typography>
                            <div className='lg:flex gap-5'>
                                <div className='lg:w-1/2'>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Full Name
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="fullname"
                                        name="fullname"
                                        value={formik.values.fullname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                                        helperText={formik.touched.fullname && formik.errors.fullname}
                                        InputProps={{
                                            startAdornment: <FaUser className="mr-2 text-[#B83855]" />,
                                            sx: { backgroundColor: '#F4F6F6' },
                                        }}
                                    />
                                </div>
                                <div className='lg:w-1/2'>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Phone Number
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="phonenumber"
                                        name="phonenumber"
                                        value={formik.values.phonenumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                                        helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                                        InputProps={{
                                            startAdornment: <FaPhone className="mr-2 text-[#B83855]" />,
                                            sx: { backgroundColor: '#F4F6F6' },
                                        }}
                                    />
                                </div>

                            </div>


                            <div className='lg:flex gap-5 mt-2'>
                                <div className='lg:w-1/2'>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Email
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        InputProps={{
                                            startAdornment: <FaEnvelope className="mr-2 text-[#B83855]" />,
                                            sx: { backgroundColor: '#F4F6F6' },
                                        }}
                                    />
                                </div>
                                <div className='lg:w-1/2'>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Charter Date
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="charterDate"
                                        name="charterDate"
                                        type="date" // This sets the input type to date
                                        value={formik.values.charterDate} // Updated to reflect the charter date field
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.charterDate && Boolean(formik.errors.charterDate)} // Updated to check 'charterDate' field
                                        helperText={formik.touched.charterDate && formik.errors.charterDate} // Displays error for 'charterDate' field
                                        InputProps={{
                                            startAdornment: <IoCalendar className="mr-2 text-[#B83855]" />, // Adjusted icon to match date context
                                            sx: { backgroundColor: '#F4F6F6' },
                                        }}
                                    />
                                </div>
                            </div>


                            <div className='lg:flex gap-5 mt-2'>
                                <div className='lg:w-1/2'>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Number of Guests
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="guests"
                                        name="guests"
                                        type="number" // This restricts input to numbers only
                                        value={formik.values.guests} // Changed to formik.values.guests
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.guests && Boolean(formik.errors.guests)} // Updated to check 'guests' field
                                        helperText={formik.touched.guests && formik.errors.guests} // Updated to display error for 'guests' field
                                        InputProps={{
                                            startAdornment: <FaPeopleGroup className="mr-2 text-[#B83855]" />,
                                            sx: { backgroundColor: '#F4F6F6' },
                                        }}
                                    />
                                </div>
                                <div className='lg:w-1/2'>

                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Charter Package
                                        </Typography>
                                        <Select

                                            id="charter"
                                            name="charter"
                                            value="Half Day Charter"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.charter && Boolean(formik.errors.charter)}
                                            label="Charter"
                                            inputProps={{
                                                sx: { backgroundColor: '#F4F6F6' },
                                            }}
                                            startAdornment={
                                                <div style={{ backgroundColor: '#F4F6F6', padding: '4px', borderRadius: '4px' }}>
                                                    <FaPeopleGroup className="mr-2 text-[#B83855]" />
                                                </div>
                                            }


                                            disabled
                                        >
                                            <MenuItem value={'Half Day Charter'} >
                                                Half Day Charter
                                            </MenuItem>
                                        </Select>
                                        {formik.touched.guests && formik.errors.guests && (
                                            <FormHelperText error>{formik.errors.guests}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>

                            </div>


                            <div className='mt-2'>

                                <Typography variant="subtitle1" color="textPrimary">
                                    Message
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="message"
                                    name="message"
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.message && Boolean(formik.errors.message)}
                                    helperText={formik.touched.message && formik.errors.message}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        startAdornment: <FaCommentDots className="mr-2 text-[#B83855]" />,
                                        sx: { backgroundColor: '#F4F6F6' },
                                    }}
                                />
                            </div>




                            <div className='mt-10'>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    startIcon={<IoMdSend size={20} />}
                                >
                                    Send
                                </Button>
                            </div>

                        </form>
                    </Container>
                </ThemeProvider>
            </div>
        </div >
    )
}

export default HalfDayAdventureReservation