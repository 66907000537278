import React, { useState, useEffect } from 'react';
import TournamentsData from '../Component/Tournaments.json';
import { Link } from '@mui/material';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GiFishing } from "react-icons/gi";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Divider } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: "#B83855", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

const LosCabosTunaJackpotTournament = () => {
    const [tournaments, settournaments] = useState([]);

    useEffect(() => {
        // Filter the offers data to only include those with the TournamentName "Los Cabos Tuna Jackpot Tournament"
        const filteredtournaments = TournamentsData.filter(tournament => tournament.TournamentName === "Los Cabos Tuna Jackpot Tournament");
        console.log("Filtered Tournaments:", filteredtournaments); // Debugging
        settournaments(filteredtournaments);
    }, []);


    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <div className="container mx-auto">
            <div className='mx-5'>
                {tournaments.map(tournament => (
                    <div key={tournament.ID}>
                        <div className='lg:flex py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-t-3xl border-b-8 border-[#ffffff]'>
                            <div className='lg:w-1/2 pl-5'>
                                <h3><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></h3>
                            </div>
                            <div className='lg:w-1/2 lg:pr-10 pl-5 lg:text-right'>
                                <div><CalendarMonthTwoToneIcon /> {tournament.From} - {tournament.To} {tournament.TournamentsYear} <span className='animate-pulse text-[#B83855]'>{tournament.Availability}</span></div>

                            </div>

                        </div>
                        <div className="relative">
                            <div className='bg-[#F4F6F6] text-[#3F68A1]'>
                                <Link href={tournament.TournamentLink}>
                                    <img src={tournament.TournamentImage} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo from ${tournament.From} to ${tournament.To} ${tournament.TournamentsYear}`} className="w-full hover:mix-blend-multiply" />
                                </Link>
                            </div>
                            <div className=''>
                                <img src={tournament.TournamentLogo} alt={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} title={`${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo`} className="absolute lg:w-1/5 w-1/3 bottom-0 right-0 bg-[#F4F6F6]/50 text-[#3F68A1] rounded-tl-3xl p-5" />
                            </div>
                        </div>
                        <div className=' mb-5 py-5 bg-[#F4F6F6] text-[#3F68A1] rounded-b-3xl border-t-8 border-[#ffffff]'>
                            <div className='lg:flex'>
                                <div className='lg:w-1/2 pl-5'>
                                    <div>
                                        <h3><b>Price: <span className='text-[#B83855]'>${tournament.TournamentPrice}</span></b> - {tournament.TournamentDuration} Days</h3>
                                    </div>
                                    <div><em>The price includes all food and drinks</em></div>
                                </div>

                                <div className='lg:w-1/2 lg:text-right lg:mt-0 mt-5 pl-5 lg:pr-10'>
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            component="a"
                                            href={tournament.TournamentLink}
                                            title={`Reservation of ${tournament.TournamentsYear} ${tournament.TournamentName} Charter in Cabo on Goin' Fission`}
                                        >
                                            <GiFishing size={35} />
                                            &nbsp;&nbsp;
                                            <b className='Custom-Font'>Check Availability</b>
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div className='px-10 mt-5'>
                                <Accordion

                                    expanded={expanded === 'panel1'}
                                    onChange={handleChange('panel1')}
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        '&:before': {
                                            display: 'none',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}

                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon style={{ color: '#B83855' }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ color: '#104895', alignItems: 'left' }}
                                    >

                                        <div className=''>Entry Fees & Schedule</div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ color: '#104895' }}>
                                        <div className='text-center pb-5'><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></div>
                                        <div className=''>The {tournament.TournamentName} is known as the richest Tuna Fishing Tournament in the world! The slogan for this event is "Fish Hard - Party Harder" which tells you that not only is there serious money involved, but this is one big party. Known for attracting the biggest fields all year, don't wait to register for this tournament amigo as we turn away last minute anglers every year.</div>
                                        <div className='my-2'><Divider /></div>


                                        <div className='text-center pt-5'><b>{tournament.TournamentsYear} {tournament.TournamentName}</b></div>
                                        <div className='my-2'><Divider /></div>
                                        <div className='text-center pb-5'>Schedule of Events</div>

                                        <div className='text-center'>Exact details on the schedule of events is pending as of January 2024, Please call for details</div>

                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LosCabosTunaJackpotTournament