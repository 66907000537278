import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Container, Grid, Typography, ThemeProvider, createTheme } from '@mui/material';
import { FaUser, FaPhone, FaEnvelope, FaCommentDots } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import ContactList from './Component/Contact';
import emailjs from 'emailjs-com';

const theme = createTheme({
    palette: {
        primary: {
            main: "#B83855", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

const Contact = () => {

    const formik = useFormik({
        initialValues: {
            fullname: '',
            phonenumber: '',
            email: '',
            message: '',
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Full Name is required'),
            phonenumber: Yup.string().required('Phone Number is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            message: Yup.string().required('Message is required'),
        }),
        onSubmit: (values, { resetForm }) => {
            emailjs.sendForm('service_ylpkqul', 'template_wowzeg6', '#contactForm', 'ljj8dcz8Z_jg6pnQu')
                .then((result) => {
                    console.log(result.text);
                    alert('Message sent successfully!');
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to send the message, please try again.');
                });
        },
    });

    return (
        <div className='lg:flex lg:gap-5 py-5'>
            <div className='lg:w-2/3'>
                <ThemeProvider theme={theme}>
                    <Container maxWidth="md">
                        <form id="contactForm" onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" color="primary" gutterBottom>
                                        Contact Goin' Fission
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="relative">
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Full Name
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="fullname"
                                            name="fullname"
                                            value={formik.values.fullname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                                            helperText={formik.touched.fullname && formik.errors.fullname}
                                            InputProps={{
                                                startAdornment: <FaUser className="mr-2 text-[#B83855]" />,
                                                sx: { backgroundColor: '#F4F6F6' },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="relative">
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Phone Number
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="phonenumber"
                                            name="phonenumber"
                                            value={formik.values.phonenumber}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                                            helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                                            InputProps={{
                                                startAdornment: <FaPhone className="mr-2 text-[#B83855]" />,
                                                sx: { backgroundColor: '#F4F6F6' },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="relative">
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Email
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            InputProps={{
                                                startAdornment: <FaEnvelope className="mr-2 text-[#B83855]" />,
                                                sx: { backgroundColor: '#F4F6F6' },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="relative">
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Message
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="message"
                                            name="message"
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                            multiline
                                            rows={4}
                                            InputProps={{
                                                startAdornment: <FaCommentDots className="mr-2 text-[#B83855]" />,
                                                sx: { backgroundColor: '#F4F6F6' },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                        startIcon={<IoMdSend size={20} />}
                                    >
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </ThemeProvider>
            </div>
            <div className='lg:w-1/3'>
                <div className='lg:mt-20 mt-10 container'>
                    <div className='mx-5'>
                        <ContactList />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
