import React from 'react'
import { Helmet } from 'react-helmet';
import CompanyName from './Component/CompanyName';
import { Link } from '@mui/material';


const Home = () => {



    return (
        <div className="container mx-auto">
            <Helmet>
                <title data-react-helmet="true">Goin' Fission - Charter Fishing in Cabo, Mexico</title>
                <meta data-react-helmet="true" name="description" content="Join Goin' Fission for the best charter fishing experience in Cabo, Mexico. Book your adventure today!" />
                <meta data-react-helmet="true" property="og:title" content="Goin' Fission - Charter Fishing in Cabo, Mexico" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://chartergoinfissioncabo.com/" />
                <meta data-react-helmet="true" property="og:image" content="https://chartergoinfissioncabo.com/assets/oghome.jpg" />
                <meta data-react-helmet="true" property="og:description" content="Join Goin' Fission for the best charter fishing experience in Cabo, Mexico. Book your adventure today!" />
            </Helmet>
            <div className='container mx-auto pt-10'>
                <div className='mx-5'>
                    <div className='text-2xl pb-5'><h1>Welcome to <CompanyName /> Fishing Charters in Cabo San Lucas, Mexico,</h1></div>
                    <div className='pb-5'>where the adventure begins aboard the <Link href='/GoinFission' title=''>2001 70' Hatteras Sport Fisherman</Link> with <Link href='/Crew/CaptainDavidMarquez' title='Captain David Marquez'>Captain David Marquez</Link> at the helm! Immerse yourself in the excitement of sportfishing against the stunning backdrop of the Cabo San Lucas coastline.</div>
                    <div className='pb-5'>Experience the thrill of deep-sea angling on our premium charter boats, tailored to cater to anglers of all levels. From marlin and tuna to nocturnal species, our charters offer a wide range of options for every angler.</div>
                    <div className='pb-5'>For beginners, our guided offshore fishing trips provide the perfect introduction to the sport, led by expert captains like David Marquez ensuring safety and success. Seasoned anglers can enjoy full-day deep-sea excursions, testing their skills against the ocean's toughest predators.</div>
                    <div className='pb-5'>Planning a special event? Our exclusive fishing charters are ideal for corporate outings or celebrations, guaranteeing an unforgettable experience on Cabo's waters. And for eco-conscious adventurers, our charters prioritize sustainability to protect our marine environment.</div>
                    <div className='pb-5'>With our all-inclusive charter packages, every detail is taken care of, allowing you to focus on the thrill of the catch. Indulge in luxury with gourmet catering options, ensuring your fishing adventure is both exhilarating and delicious.</div>
                    <div>Whether you seek adventure, relaxation, or the joy of reeling in the big one, a fishing charter in Cabo San Lucas promises an unparalleled experience. Get ready to create memories that will last a lifetime as you embark on this unforgettable fishing journey in one of the world's top sportfishing destinations.</div>
                </div>
            </div>
            {/* <div><Offers /></div>
            <div><Tournaments /></div> */}


        </div>
    )
}

export default Home