import React, { useEffect } from 'react';

const Weather = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'weatherwidget-io-js';
        script.src = 'https://weatherwidget.io/js/widget.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <a
            className="weatherwidget-io"
            href="https://forecast7.com/en/22d89n109d92/cabo-san-lucas/?unit=us"
            data-label_1="Cabo San Lucas"
            data-label_2="Weather"
            data-icons="Climacons Animated"
            data-theme="original"
            data-basecolor="#F4F6F6"
            data-textcolor="#3F68A1"
            data-highcolor="#B83855"
            data-lowcolor="#1B7C88"

        >
            Cabo San Lucas Weather
        </a>
    );
};

export default Weather;